import { useState } from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";
import Arrow from "../image/arrow.png";
import Review from "../image/Irene Tsang.png";
import Review1 from "../image/Nikhil Verma.png";
import Review2 from "../image/Marcos Bulacio.png";
import Review3 from "../image/Robin Yovianto.png";
import Review4 from "../image/portfolio4.jpg";
import logo from "../image/logo2.png";
import logo1 from "../image/logo3.png";
import logo2 from "../image/logo4.png";
import logo3 from "../image/logo5.png";
import logo4 from "../image/logo6.png";
import logo5 from "../image/logo7.png";
import logo6 from "../image/logo8.png";
import logo7 from "../image/logo9.png";
import Quotes from "../image/quotes.png";
import QuotesBottom from "../image/quts-bottom.png";
function Portfolio() {
  return (
    <div className="main_wrapper">
      <Header />
      <div className="main_container">
        <div className="sub-banner portfolio-sub-banner">
          <div className="container">
            <div className="sub-banner-head">
              Our Growing<br/>
              Portfolio
            </div>
            <div className="d-flex align-items-center">
              <div className="mr-4">
                <img src={Arrow} alt="Arrow" className="img-fluid" />
              </div>
              <div className="sub-banner-content">
                The partner of choice for empowering founders
                and enabling success.
              </div>
            </div>
          </div>
        </div>
        <div className="portfolio-client">
          <div className="container">
            <div className="row investment-pading">
            <div className="col-lg-3 col-6">
                <div className="client-logos">
                  <img src={logo} alt="logo" className="img-fluid" />
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="client-logos">
                  <img src={logo1} alt="logo" className="img-fluid" />
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="client-logos">
                  <img src={logo2} alt="logo" className="img-fluid" />
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="client-logos">
                  <img src={logo3} alt="logo" className="img-fluid" />
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="client-logos">
                  <img src={logo4} alt="logo" className="img-fluid" />
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="client-logos">
                  <img src={logo5} alt="logo" className="img-fluid" />
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="client-logos">
                  <img src={logo6} alt="logo" className="img-fluid" />
                </div>
              </div>
              <div className="col-lg-3 col-6">
                <div className="client-logos">
                  <img src={logo7} alt="logo" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="Passionate-founder-wrap">
          <div className="helping-line"></div>
          <div className="container">
            <div className="row">
              <div className="col-12 mb-5 ">
                <div className="sub-banner-head">
                  <div>Hear From Our</div>
                  <div>Passionate Founders</div>
                </div>
              </div>
              <div className="reviews">
                <div className="review">
                  <span className="top-quotes">
                    <img src={Quotes} alt="Quotes" className="img-fluid pr-4 top-quotes-img" width="400" />
                  </span>
                  <div>
                    <p>
                      We are immensely grateful for the opportunity to partner with Braxton
                      Capital. Their support, extensive network, and insights have been
                      instrumental to our expansion in Asia. They genuinely care about our team
                      and our mission to empower female entrepreneurs, offering strategic
                      advice that resonates deeply with our goals.
                    </p>
                    <p>
                      Their commitment to funding impact focused startups like LIFT Women
                      aligns perfectly with our vision, fueling our growth. We value their
                      dedication not just as financial backers but as true partners in making the
                      world a better place. Braxton’s belief and investment in Lift Women are
                      catalyzing the transformation of the funding landscape for women for
                      generations to come. We’re excited and confident about what’s ahead and
                      look forward to building a great venture together.
                    </p>
                  </div>

                  <span className="quotesBottom">
                    <img src={QuotesBottom} alt="QuotesBottom" className="img-fluid pl-4 QuotesBottomimg" width="400" />
                  </span>
                </div>
                <div className="review-details">
                  <img src={Review} alt="Review" className="img-fluid" />
                  <div className="review-data">
                    <div className="reviewer-name">Irene Tsang</div>
                    <div className="reviewer-founder">Founder and CEO</div>
                    <div className="reviewer-founder">LIFT Women</div>
                  </div>
                </div>
              </div>
              <div className="reviews reviews-left">
                <div className="review-details">
                  <img src={Review1} alt="Review" className="img-fluid" />
                  <div className="review-data">
                    <div className="reviewer-name">Nikhil Verma</div>
                    <div className="reviewer-founder">Founder and CEO</div>
                    <div className="reviewer-founder">Livewell</div>
                  </div>
                </div>
                <div className="review">
                  <span>
                    <img src={Quotes} alt="Quotes" className="img-fluid pr-4 top-quotes-img" width="400" />
                  </span>
                  <div>
                    <p>
                      Working with Braxton Capital has been a seamless experience. Their
                      expertise in insurance, insightful understanding of the industry, and
                      willingness to leverage their network have been invaluable during our brief
                      interaction.
                    </p>
                    <p>
                      They made the due diligence process incredibly easy, and their
                      transparency has been a standout feature. It's evident that they are
                      genuinely enthusiastic about our vision, and their passion for building
                      LivWell together is exceptional. Moreover, our alignment in chemistry and
                      thinking across the board is a significant advantage for a founder,
                      especially when dealing with multiple personalities on the board.
                    </p>
                  </div>
                  <span className="quotesBottom">
                    <img src={QuotesBottom} alt="QuotesBottom" className="img-fluid pl-4 QuotesBottomimg" width="400" />
                  </span>
                </div>
              </div>
              <div className="reviews">
                <div className="review">
                  <span className="top-quotes">
                    <img src={Quotes} alt="Quotes" className="img-fluid pr-4 top-quotes-img" width="400" />
                  </span>
                  <div>
                    <p>
                      I cannot speak highly enough of my experience with Braxton. I've dealt
                      with a dozen VCs and angel investors over the last 4 years, and Braxton
                      stood out for their unwavering commitment and support. Douglas, in
                      particular, went above and beyond the traditional role of a VC investor and
                      dedicated significant time and effort to help us refine our business case
                      and model, offering invaluable insights and guidance every step of the way.
                    </p>
                    <p>
                      Beyond the financial investment, they truly act as a mentor, leveraging
                      their expertise to help us navigate negotiations and explore potential joint
                      ventures. Their dedication and genuine interest in our success have been
                      instrumental in shaping our journey.
                    </p>
                  </div>

                  <span className="quotesBottom">
                    <img src={QuotesBottom} alt="QuotesBottom" className="img-fluid pl-4 QuotesBottomimg" width="400" />
                  </span>
                </div>
                <div className="review-details">
                  <img src={Review2} alt="Review" className="img-fluid" />
                  <div className="review-data">
                    <div className="reviewer-name">Marcos Bulacio</div>
                    <div className="reviewer-founder">Founder and CEO</div>
                    <div className="reviewer-founder">Pangea</div>
                  </div>
                </div>
              </div>
              <div className="reviews reviews-left">
                <div className="review-details">
                  <img src={Review3} alt="Review" className="img-fluid" />
                  <div className="review-data">
                    <div className="reviewer-name">Robin Yovianto</div>
                    <div className="reviewer-founder">Founder and CEO</div>
                    <div className="reviewer-founder">AMODA</div>
                  </div>
                </div>
                <div className="review">
                  <span>
                    <img src={Quotes} alt="Quotes" className="img-fluid pr-4 top-quotes-img" width="400" />
                  </span>
                  <div>
                    <p>
                      Braxton has been an extraordinary partner in AMODA's journey. Doug and
                      Edward's support goes beyond just guidance, capital, and an extensive
                      network; They truly invest in our vision to revolutionizing the real estate
                      sector - something that is quite rare these days to find form a partner.
                      Braxton's strong belief in AMODA provided us safe haven that allows us to
                      focus on achieving greater things and sustainable impact.
                    </p>
                    <p>
                      Specifically for Doug, he took the time to understand our goals, providing
                      personalized advice that is both practical and inspiring. We're incredibly
                      grateful for Braxton’s unwavering commitment in willing to collaborate
                      together with us and our existing investors in achieving a new future that
                      is impactful for the global society.
                    </p>
                  </div>
                  <span className="quotesBottom">
                    <img src={QuotesBottom} alt="QuotesBottom" className="img-fluid pl-4 QuotesBottomimg" width="400" />
                  </span>
                </div>

              </div>
              <div className="reviews">
                <div className="review">
                  <span className="top-quotes">
                    <img src={Quotes} alt="Quotes" className="img-fluid pr-4 top-quotes-img" width="400" />
                  </span>
                  <div>
                    <p>
                    We deeply thank Braxton Capital for unwavering support and trust in Bang Jamin. Their expert guidance and exceptional industry insights exemplify true partnership. Despite tech challenges, their confidence strengthens our resolve. Braxton Capital's impact energizes our future. We genuinely appreciate their steadfast support and commitment to our growth.
                    </p>
                   
                  </div>

                  <span className="quotesBottom">
                    <img src={QuotesBottom} alt="QuotesBottom" className="img-fluid pl-4 QuotesBottomimg" width="400" />
                  </span>
                </div>
                <div className="review-details">
                  <img src={Review4} alt="Review" className="img-fluid" />
                  <div className="review-data">
                    <div className="reviewer-name">Pak Indra Baruna</div>
                    <div className="reviewer-founder">Founder and CEO</div>
                    <div className="reviewer-founder">Bang Jamin</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Portfolio;
