import { useState, useRef, useEffect } from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";
import Banner from "../braxton/home-banner";
import Map from "../braxton/home-map";
import Clientlogo from "../braxton/home-clients";
function LandingPage() {
  return (
    <div className="main_wrapper">
      <Header />
      <div className="main_container">
        <Banner/>
        <Map/>
        <Clientlogo/>
        
      </div>
      <Footer />
    </div>
  );
}

export default LandingPage;
