import { useState } from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";
import Banner from "../braxton/home-banner";
import Arrow from "../image/arrow.png";
import img1 from "../image/Douglas Hudson.png";
import img2 from "../image/Edward Ismawan.png";
import img3 from "../image/Angela Soon.png";
import img4 from "../image/Randy Lianggara.png";
import img5 from "../image/Gordon Watson.png";
import img6 from "../image/Goh Wee Ping.png";
import img7 from "../image/Dharuma Tharu.png";
import Linkdn from "../image/in.png";
import { Link } from "react-router-dom";

function Team() {
  const teamMembers = [
    {
      id: 1,
      name: "Douglas Hudson",
      role: "Managing Partner",
      image: img1,
      description: "Over 25 years Strategy, M&A, PE / VC experience, 20+ years in Asia",
      moreInfo: `10 years at AIG / AIA as Regional Head leading the $35bn AIA IPO, $1bn Philam transaction / BPI partnership, $120m AIA Taiwan launch, Chief Partnership Distribution Officer, Head of $1bn AIA-CITI partnership<br/><br/>
     10 years at Deloitte Consulting (selected as top 5% of managers) working with the Asia Pacific CEO in Hong Kong, Special Transactions Team with KKR in San Francisco, and Strategy and Operations Team throughout the US and Europe.`,
      linkdn: "https://www.linkedin.com/in/douglaswhudson/",
    }
    ,
    {
      id: 2,
      name: "Edward Ismawan Chamdani",
      role: "General Partner",
      image: img2,
      description: "Over 25 years as an Investment Strategist and Venture Capital investor in Southeast Asia.",
      moreInfo: "As a Co-Founder and Managing Partner at Ideosource and Gaya Capital, a venture capital firm dedicated to investing in Indonesian digital startups, Edward's deep understanding of technology trends, visionary leadership, and talent for cultivating entrepreneurial aptitude have cemented his position as an industry leader. He boasts an impressive portfolio of early-stage investments, which includes burgeoning industry players such as eFishery, Stockbit, Bibit, aCommerce, Bhinneka, and Andalin.<br/><br/> Prior to co-founding Ideosource, Edward honed his expertise in the tech sector at IBM Indonesia. His vast industry experience also includes advisory roles for several tech startups, leveraging his strong analytical skills, keen business acumen, and broad understanding of local and international markets.<br/><br/>Beyond his investment pursuits, Edward is a respected mentor and advisor in the tech startup community. He is often sought for his insights at conferences and discussions on topics of venture capital, entrepreneurship, and digital innovation. His unwavering dedication to nurturing growth and innovation in the technology sector stands testament to his significant contributions to the private equity and venture capital industry.",
      linkdn:"https://www.linkedin.com/in/eichamdani/",
    },
    {
      id: 3,
      name: "Angela Soon",
      role: "Associate Director",
      image: img3,
      description: "15 years in Corporate Restructuring, M&A, Value Creation, Private Investments, Private Credit, Special Situations in Indonesia and Singapore",
      moreInfo: "Angela has over 15 years of experience in the financial industry navigating the unique challenges and complexities in Indonesia and Singapore. She co-founded Kaben Ventures Pte Ltd in 2016 and specializes in private investments, restructuring, M&A, private credit, special situations and corporate finance. She has a stellar track record in identifying and executing impactful investment opportunities and can navigate complex issues and resolve operational issues with finesse. Prior to her role at Braxton, Angela held a senior role at EY, where she managed engagements valued at over US$100m. She successfully executed auctions and optimization of portfolio companies through strategic cashflow and liquidity measures. Her diverse portfolio companies span a spectrum of industries, including Manufacturing, IT, Retail, Plantations, Renewables, Real Estate, Fintech, etc. In July 2023, she completed her Master in Applied Finance from Singapore Management University. Additionally, she loves to learn and is fluent in English, Mandarin, and Bahasa Indonesia. Beyond the financials, Angela is passionate about the Environment, Diversity, Women Empowerment, Impact Investments and Sustainable Finance.",
      linkdn:"https://www.linkedin.com/in/angelasoon/",
    },
    {
      id: 4,
      name: "Randy Lianggara",
      role: "Advisor",
      image: img4,
      description: "President, Emerging Markets, Asia, Sunlife (2024-present).  Regional CEO, Asia, Aviva (2016-2024)",
      moreInfo: "Randy was appointed to the boards of Singapore Life Holdings Pte. Ltd. and Singapore Life Ltd. as a Non-Executive Director in August 2021. He is a member of the Nominating Committee and the Remuneration Committee.<br/><br/> Randy is an insurance veteran with more than 30 years of experience. He joined Aviva in June 2016 and currently serves as Regional CEO, Asia. Prior to joining Aviva, Randy served as AXA's Country CEO in Indonesia for 10 years, managing Life Insurance, General Insurance and Asset Management.<br/><br/> Within his capacity in Aviva, Randy holds directorships in Aviva-Coco Life Insurance (China), Aviva Life Insurance (India) as well as Aviva Asia Management (Singapore).<br/><br/> He is a Certified Financial Planner, Certified Life Underwriter, Certified Wealth Manager, Certified Professional in Life and Health Insurance, and a ChFC Charter Holder.<br/><br/> Randy holds a Bachelor's degree from lowa State University and a Master of Business Administration from Golden Gate University.",
      linkdn:"https://www.linkedin.com/in/randy-lianggara/?originalSubdomain=sg",
    },
    {
      id: 5,
      name: "Gordon Watson",
      role: "Advisor",
      image: img5,
      description: "Chairman, AXA Asia; CEO of AXA Asia and Africa, overseeing operations in 18 markets in Asia, Africa, Middle East; Global Head, Emerging Customers (2017-current)",
      moreInfo: "Gordon Watson n is a distinguished global business leader with over 30 years of experience in the insurance and financial services industry. He currently serves as the Non-Executive Chairman of AXA Asia, having previously led AXA’s operations across 18 markets in Asia, Africa, and the Middle East as CEO. During his tenure, he spearheaded strategic initiatives such as AXA Emerging Customers, expanding insurance access to underserved communities while aligning business growth with social impact.<br/><br/> Prior to AXA, Gordon held senior leadership roles at AIA and AIG, where he was instrumental in driving growth across key markets. As Regional Chief Executive at AIA, he played a pivotal role in the company’s post-IPO expansion across Hong Kong, Australia, and New Zealand. At AIG, he held various executive positions, including CEO of AIG Life Japan and Global Executive Vice Chairman of Alico (now MetLife), overseeing operations in more than 50 countries.<br/><br/> Beyond his corporate achievements, Gordon is a passionate advocate for mental health and social impact. As the founding Chair of the Shared Value Initiative Hong Kong, he promotes sustainable business practices that address social and environmental challenges. He also cochairs One Mind at Work, collaborating with global organizations to improve workplace mental health strategies.<br/><br/> In addition to serving on multiple boards, including LL Global and Cyberport’s advisory board, Gordon actively advises fintech firms. He holds an MBA with distinction from the University of Hull, is a Fellow of the Chartered Insurance Institute (FCII), and recently earned a master’s degree in Global Mental Health from the University of Glasgow.",
      linkdn:""
    },
    {
      id: 6,
      name: "Goh Wee Ping",
      role: "Advisor",
      image: img6,
      description: "Goh Wee Ping is a highly accomplished CEO and investor with a proven track record of success.",
      moreInfo: "Goh Wee Ping is a highly accomplished CEO and investor with a proven track record of success. As the CEO of Wee Hur Capital, he is responsible for growing the fund management arm, with a focus on Purpose Built Student Accommodation (PBSA) in Australia. Under his leadership, Wee Hur Capital has grown its assets under management to over $1.5 billion. In addition to his role at Wee Hur Capital, Wee Ping is also the founder of KK39 Ventures, the Corporate Venture Capital (CVC) arm of Wee Hur. With a mandate to invest in both VC funds and early stage startups, KK39 Ventures focuses on identifying innovative solutions that bring about efficiencies to the core business and consumers. He holds a Bachelor of Engineering, Civil Engineering from NUS.",
      linkdn:"https://www.linkedin.com/in/weeps/?originalSubdomain=sg"
    },
    {
      id: 7,
      name: "Dharuma Tharu",
      role: "Advisor",
      image: img7,
      description: "Dharuma has over 20 years of experience in the financial service industry and since 2016….”",
      moreInfo: "Mr Tharu, who has over 20 years of experience in the financial industry, has been, since 2016, serving as the Co-Founder and Managing Partner of LT capital Pte Ltd, an alternative direct investment firm based in Singapore which focuses on sourcing and structuring exclusive investments opportunities in partnership with trusted local partners, comprising family-run conglomerates in S.E. Asia. Prior to LT Capital, Mr Tharu served as Chief Operating Officer and Head of Compliance for Oclaner Asset Management Pte. Ltd. since 2010. During his tenure at Oclaner, Mr Tharu oversaw the operational setup of various family offices and investments platforms. Working closely with European and Asian families he was responsible for setting up personalized Family Offices. In 2013, Mr Tharu started the Direct Investment business arm for Oclaner, which involved the origination of unique Real Estate investment opportunities in South East Asia. Prior to Oclaner, he was a senior private banker at LGT Bank in 2009. From 2002 to 2009, Mr Tharu worked in HSBC under their regional manager program where he had roles as an FX trader in the Treasury & Capital Markets department and as a private banker with HSBC Private Bank. During his career, Mr Tharu developed an extensive network of investors and business operators from Malaysia, Singapore, Indonesia and Hong Kong. Mr Tharu has a Masters in Chemical Engineering (MEng) from University College London, which he acquired in 2001. He also has a diploma in basic accounting principles from the London School of Economics. In 2002, he concluded research on supercritical fluids and was awarded publication in the Chemical Engineering Transactions Journal for his contribution to the field of Chemical and Process Engineering.",
      linkdn:'https://www.linkedin.com/in/dharuma-tharu-8b4410100/'
    },
  ];

  const [expanded, setExpanded] = useState({});

  const toggleExpand = (id) => {
    setExpanded((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  return (
    <div className="main_wrapper">
      <Header />
      <div className="main_container">
        <div className="sub-banner">
          <div className="container">
            <div className="sub-banner-head">
              Insightful, Committed, Experienced Team.
            </div>
            <div className="d-flex align-items-center">
              <div className="mr-4">
                <img src={Arrow} alt="Arrow" className="img-fluid" />
              </div>
              <div className="sub-banner-content">
                Lead by seasoned professionals dedicated to driving growth, delivering value, and creating lasting impact.
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="team-wrap">
            <div className="row mb-5 justify-content-center team-gap ">
              {teamMembers.map((member) => (
                <div key={member.id} className="col-lg-4 col-12 mb-5 col-md-6 px-lg-4">
                  <div className="card shadow-sm">
                    <div className="team-bg">
                      <img src={member.image} alt={member.name} className="img-fluid" width="100%" />
                    </div>
                    <div className="card-body">
                      <div className="team-topbar">
                        <div className="team-datas">
                          <div className="team-name">{member.name}</div>
                          <p className="team-role">{member.role}</p>
                        </div>
                        <a className="linkdn-team" href={member.linkdn} target="_blank">
                          <img src={Linkdn} alt="LinkedIn" className="img-fluid" width="25"/>
                        </a>
                      </div>
                      <div className="team-description">
                        <p>{member.description}</p>
                        {expanded[member.id] && (
                        <div className="more-div">
                          {/* <p >{member.moreInfo}</p> */}
                          <p dangerouslySetInnerHTML={{ __html: member.moreInfo }} />
                        </div>
                      )}
                      </div>
                     
                      <div
                        className="more-btn"
                        onClick={() => toggleExpand(member.id)}
                        style={{ cursor: "pointer" }}
                      >
                        {expanded[member.id] ? "less" : "more"} <span>{expanded[member.id] ? "-" : "+"}</span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Team;
