import { useState } from "react";
import Header from "../layout/header";
import Footer from "../layout/footer";
import Arrow from "../image/arrow.png";
import Focusimg from "../image/focus.png";
import Icon1 from "../image/icon-w1.png";
import Icon from "../image/icon-w.png";
import Icon2 from "../image/icon-w2.png";
import Icon3 from "../image/icon-w3.png";

function Focus() {


  return (
    <div className="main_wrapper">
      <Header />
      <div className="main_container">
        <div className="sub-banner">
          <div className="container">
            <div className="sub-banner-head">
              Driving Business Performance
              through Investment, Advisory,
              and Growth.
            </div>
            <div className="d-flex align-items-center">
              <div className="mr-4">
                <img src={Arrow} alt="Arrow" className="img-fluid" />
              </div>
              <div className="sub-banner-content">
                Braxton is a Singapore-based venture capital firm focused on early
                stage development and growth equity opportunities in Southeast Asia,
                with a strategic focus on Impact, Insurance, and Health.
              </div>
            </div>
          </div>
        </div>

        <div className="focus-wrap">
          <div className="approach-line"></div>
          <div className="row mb-5 justify-content-center">
            <div className="container">
              <div className="row">
                <div className="col-12 col-lg-6 focus-left">
                  <div className="sub-banner-head">
                    Our Approach
                  </div>
                  <div className="focus-description">
                    <p className="focus-para">
                      Braxton applies a pragmatic approach to
                      investment decisions and operating
                      principles to generate significant risk
                      adjusted returns
                    </p>
                    <p className="focus-para font-light">
                      Braxton invests in businesses that focus on
                      fundamentals and future profitability using
                      a proactive hands on approach to reduce
                      risk, accelerate growth, and maximize re-
                    </p>
                  </div>

                </div>
                <div className="col-12 col-lg-6">
                  <div>
                    <img src={Focusimg} alt="Focus" className="img-fluid" width="500" />
                  </div>
                </div>
              </div>

              <div>

              </div>
            </div>
          </div>
        </div>

        <div className="helping-founders">
          <div className="helping-line"></div>
          <div className="container">
            <div className="row">
              <div className="col-12 mb-5 mb-lg-2">
                <div className="sub-banner-head">
                  <div>Helping Founders</div>
                  <div>Build Great Businesses</div>
                </div>
              </div>
              <div className="col-lg-6 col-12 col-md-12 help-bottom mt-lg-5">
                <div className="build-great-business">
                  <div className="helping-founder-icon">
                    <img src={Icon} alt="Icon1" className="img-fluid"  width="110" height="110"/>
                  </div>
                  <div className="helping-founder-desc">
                    Southeast Asia, home to
                    over 600 million people, is
                    one of the world’s fastest
                    growing regions
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-12 col-md-12 help-bottom mt-lg-5">
                <div className="build-great-business">
                  <div className="helping-founder-icon p-lg-0 p-xl-0">
                    <img src={Icon1} alt="Icon1" className="img-fluid" width="110" height="110"/>
                  </div>
                  <div className="helping-founder-desc">
                  Financials first approach leading
to significant social impact can
coexist
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-12 col-md-12">
                <div className="build-great-business">
                  <div className="helping-founder-icon">
                    <img src={Icon2} alt="Icon1" className="img-fluid"  width="110" height="110"/>
                  </div>
                  <div className="helping-founder-desc">
                    Invest in businesses with
                    clear paths to profitability,
                    solid fundamentals, and
                    achievable strategic plans
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-12 col-md-12">
                <div className="build-great-business">
                  <div className="helping-founder-icon">
                    <img src={Icon3} alt="Icon1" className="img-fluid"  width="110" height="110"/>
                  </div>
                  <div className="helping-founder-desc">
                    Partner with mature
                    founders to shepherd,
                    guide, and accelerate their
                    growth trajectory
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Focus;
