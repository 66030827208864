import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "../css/bootstrap.css";
import "../css/style.css";
import "../css/responsive.css";
import Logo from "../image/logo.png";
import { FiMenu, FiX } from "react-icons/fi"; // Import icons

function Header() {
  const [menuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // Function to handle menu click
  const handleMenuClick = (path) => {
    navigate(path);
    setMenuOpen(false); // Close menu on mobile
  };

  return (
    <div className="header-wrap">
      <div className="container">
        <header className="d-flex flex-wrap justify-content-between py-4 align-items-center">
          {/* Logo */}
          <a href="/" className="d-flex align-items-center text-dark text-decoration-none">
            <img src={Logo} alt="Logo" className="main-logo" width="250" />
          </a>

          {/* Hamburger Icon */}
          <div className="d-block d-lg-none" onClick={() => setMenuOpen(!menuOpen)}>
            {menuOpen ? <FiX size={30} /> : <FiMenu size={30} />}
          </div>

          {/* Navigation Menu */}
          <ul className={`nav nav-pills ${menuOpen ? "mobile-menu open" : "d-none d-lg-flex"}`}>
            <li className="nav-item">
              <a
                href="#"
                className={`nav-link ${location.pathname === "/focus" ? "nav-active" : ""}`}
                onClick={(e) => {
                  e.preventDefault();
                  handleMenuClick("/focus");
                }}
              >
                FOCUS
              </a>
            </li>
            <li className="nav-item">
              <a
                href="#"
                className={`nav-link ${location.pathname === "/investment" ? "nav-active" : ""}`}
                onClick={(e) => {
                  e.preventDefault();
                  handleMenuClick("/investment");
                }}
              >
                INVESTMENT
              </a>
            </li>
            <li className="nav-item">
              <a
                href="#"
                className={`nav-link ${location.pathname === "/portfolio" ? "nav-active" : ""}`}
                onClick={(e) => {
                  e.preventDefault();
                  handleMenuClick("/portfolio");
                }}
              >
                PORTFOLIO
              </a>
            </li>
            <li className="nav-item">
              <a
                href="#"
                className={`nav-link ${location.pathname === "/team" ? "nav-active" : ""}`}
                onClick={(e) => {
                  e.preventDefault();
                  handleMenuClick("/team");
                }}
              >
                TEAM
              </a>
            </li>
            <li className="nav-item ml-lg-2">
              <a
                // href="#"
                className="nav-link navlink-btn px-3 font-light investor-login"
                // onClick={(e) => {
                //   e.preventDefault();
                //   handleMenuClick("/investor-login");
                // }}
              >
                INVESTOR LOGIN
              </a>
            </li>
          </ul>
        </header>
      </div>
    </div>
  );
}

export default Header;
