import { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import "../css/slick.css";
import "../css/slick-theme.css";
import logo from "../image/logo2.png";
import logo1 from "../image/logo3.png";
import logo2 from "../image/logo4.png";
import logo3 from "../image/logo5.png";
import logo4 from "../image/logo6.png";
import logo5 from "../image/logo7.png";
import logo6 from "../image/logo8.png";
import logo7 from "../image/logo9.png";

function Clientlogos() {
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow:8,
        centerMode: true, 
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false ,
        responsive: [
            
            {
                breakpoint: 1800, // Mobile landscape
                settings: {
                    slidesToShow: 6,
                }
            },
            {
                breakpoint: 1460, // Mobile landscape
                settings: {
                    slidesToShow: 5,
                }
            },
            {
                breakpoint: 768, // Mobile landscape
                settings: {
                    slidesToShow: 3,
                    centerMode: false, 
                }
            }
        ]
    };

    return (
        <div className="client-wrapper pt-4 pb-5">
            <div className="row m-auto">
                <div className="col-lg-3 ">
                </div>
                <div className="col-2 col-lg-2 col-4">
                    <div className="client-heads">
                        IMPACT
                    </div>
                </div>
                <div className="col-3 col-lg-2 col-4">
                    <div className="client-heads">
                        INSURANCE
                    </div>
                </div>
                <div className="col-3 col-lg-2 col-4">
                    <div className="client-heads">
                        HEALTH
                    </div>
                </div>
                <div className="col-lg-3">
                </div>
            </div>
            <Slider {...settings}>
            <div>                  
                   <div className="client-logos">
                        <img src={logo} alt="logo1" className="img-fluid" width="150" height="150"/>
                   </div>
                </div>
                <div>                  
                   <div className="client-logos">
                        <img src={logo1} alt="logo1" className="img-fluid" width="150" height="150"/>
                   </div>
                </div>
                <div>                  
                   <div className="client-logos">
                        <img src={logo2} alt="logo2" className="img-fluid" width="150" height="150"/>
                   </div>
                </div>
                <div>                  
                   <div className="client-logos">
                        <img src={logo3} alt="logo3" className="img-fluid" width="150" height="150"/>
                   </div>
                </div>

                <div>                  
                   <div className="client-logos">
                        <img src={logo4} alt="logo4" className="img-fluid" width="150" height="150"/>
                   </div>
                </div>
                <div>                  
                   <div className="client-logos">
                        <img src={logo5} alt="logo5" className="img-fluid" width="150" height="150"/>
                   </div>
                </div>
                <div>                  
                   <div className="client-logos">
                        <img src={logo6} alt="logo6" className="img-fluid" width="150" height="150"/>
                   </div>
                </div>
                <div>                  
                   <div className="client-logos">
                        <img src={logo7} alt="logo7" className="img-fluid" width="150" height="150"/>
                   </div>
                </div>
                
            </Slider>
        </div>
    );
}

export default Clientlogos;
