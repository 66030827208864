import React from "react";
import Logo from "../image/footer-logo.png"
import FooterImg from "../image/footer-logo-orange.png"
import Location from "../image/location.png"
import Mail from "../image/mail.png"
import Linkdn from "../image/linkdn.png"

function Footer() {
    return (
        <div className="footer-wrap">
            <div className="footer-bg py-4">
                <div className="line-seperator">

                </div>
                <div className="container">
                    <div className="sub-head">Ready to join the Braxton family?</div>
                    <div className="d-md-flex justify-content-between align-items-center">
                        <div className="col-12 p-0 col-lg-8 col-md-6">
                            <div className="d-flex">
                                <div className="footer-logo mr-3">
                                    <img src={Logo} alt="Logo" className="img-fluid footer-logo-left" width="120" />
                                </div>
                                <div className="footer-left">
                                    <a href="/focus">FOCUS</a>
                                    <a href="/investment">INVESTMENT</a>
                                    <a href="/portfolio">PORTFOLIO</a>
                                    <a href="/team">TEAM</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-12 p-0 col-md-6">
                            <div className="footer-right">
                                <div className="footer-right-head">LET’S CONNECT</div>
                                <div className="d-flex footer-links">
                                    <div>
                                        <img src={Location} alt="Location" width="25" height="25" />
                                    </div>
                                    <div className="ml-2">

                                        <p className="mb-0 footer-links-text font-medium">Braxton Capital Ventures Pte. Ltd.</p>
                                        <p className="mb-0 footer-links-text font-light"> 137 Telok Ayer Street</p>
                                        <p className="mb-0 footer-links-text font-light"> Singapore 068602</p>
                                    </div>

                                </div>
                                <div className="d-flex footer-links align-items-center">
                                    <div>
                                        <img src={Mail} alt="Location" width="25" height="25" />
                                    </div>
                                    <div className="ml-2 font-light">info@braxton.com.sg</div>
                                </div>
                                <div className="d-flex footer-links align-items-center">
                                    <div>
                                        <img src={Linkdn} alt="Linkdn" width="25" height="25" />
                                    </div>
                                    <div className="ml-2 font-light">
                                        www.linkedin.com/company/braxton-capital</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className="footer-bottom">
                <div className="container">
                    <div className="footer-btm">
                        <div className="footer-bottom-sec">
                            <img src={FooterImg} alt="Logo" width="220" />
                        </div>
                        <div className="footer-bottom-sec font-light">Singapore MAS License: CMS101786</div>
                        <div className="footer-bottom-sec font-light">© Copyright 2025 Braxton Capital Pte. Ltd.</div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default Footer;
