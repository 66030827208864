import { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import "../css/slick.css";
import "../css/slick-theme.css";
import Bannerimg from "../image/banner-1.png";
import BannerLeaf from "../image/banner-leaf.png";
import Banner1 from "../image/banner-BANGKOK.png";
import Banner2 from "../image/banner-HO CHI MINH CITY.png";
import Banner3 from "../image/banner-JAKARTA.png";
import Banner4 from "../image/banner-KUALA LUMPUR.png";
import Banner5 from "../image/banner-MANILA.png";
import Banner6 from "../image/banner-mumbai.png";
import Leaf from "../image/leaf.png";

function Banner() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false
    };

    return (
        <div className="banner-wrapper pt-1">
            <Slider {...settings}>
                <div className="position-relative">
                    <div className="banner-line"></div>
                    <div className="banner-leaf">
                        <img src={BannerLeaf} alt="BannerLeaf" className="img-fluid" width="160" />
                    </div>
                    <div className="container">
                        <div className="banner-text">
                        <div className="mb-1">
                                <div>Empowering  
                                <span  className="font-medium ml-1">Visionary Founders.</span>
                                </div>                          
                            </div>
                            <div>
                                <div>Shaping the <span  className="font-medium">Future of Southeast Asia.</span></div>
                            </div>
                        </div>
                    </div>
                    <div className="banner-image">
                        <img src={Bannerimg} alt="Bannerimg" width="93%" className="img-fluid" />
                        <div className="container banner-image-text">
                            <img src={Leaf} alt="Leaf" className="img-fluid" width="40" />
                            <span className="ml-3">SINGAPORE</span>
                        </div>
                    </div>
                </div>
                <div className="position-relative">
                    {/* <div className="banner-line"></div> */}
                    <div className="banner-leaf">
                        <img src={BannerLeaf} alt="BannerLeaf" className="img-fluid" width="160" />
                    </div>
                    <div className="container">
                        <div className="banner-text">
                            <div className="mb-1">
                                <div>Empowering  
                                <span  className="font-medium ml-1">Visionary Founders.</span>
                                </div>                          
                            </div>
                            <div>
                                <div>Shaping the <span  className="font-medium">Future of Southeast Asia.</span></div>
                            </div>
                        </div>
                    </div>
                    <div className="banner-image">
                        <img src={Banner1} alt="Bannerimg" width="93%" className="img-fluid" />
                        <div className="container banner-image-text">
                            <img src={Leaf} alt="Leaf" className="img-fluid" width="40" />
                            <span className="ml-3">BANGKOK</span>
                        </div>
                    </div>
                </div>
                <div className="position-relative">
                    <div className="banner-line"></div>
                    <div className="banner-leaf">
                        <img src={BannerLeaf} alt="BannerLeaf" className="img-fluid" width="160" />
                    </div>
                    <div className="container">
                        <div className="banner-text">
                        <div className="mb-1">
                                <div>Empowering  
                                <span  className="font-medium ml-1">Visionary Founders.</span>
                                </div>                          
                            </div>
                            <div>
                                <div>Shaping the <span  className="font-medium">Future of Southeast Asia.</span></div>
                            </div>
                        </div>
                    </div>
                    <div className="banner-image">
                        <img src={Banner2} alt="Bannerimg" width="93%" className="img-fluid" />
                        <div className="container banner-image-text">
                            <img src={Leaf} alt="Leaf" className="img-fluid" width="40" />
                            <span className="ml-3">HO CHI MINH CITY</span>
                        </div>
                    </div>
                </div>
                <div className="position-relative">
                    <div className="banner-line"></div>
                    <div className="banner-leaf">
                        <img src={BannerLeaf} alt="BannerLeaf" className="img-fluid" width="160" />
                    </div>
                    <div className="container">
                        <div className="banner-text">
                        <div className="mb-1">
                                <div>Empowering  
                                <span  className="font-medium ml-1">Visionary Founders.</span>
                                </div>                          
                            </div>
                            <div>
                                <div>Shaping the <span  className="font-medium">Future of Southeast Asia.</span></div>
                            </div>
                        </div>
                    </div>
                    <div className="banner-image">
                        <img src={Banner3} alt="Bannerimg" width="93%" className="img-fluid" />
                        <div className="container banner-image-text">
                            <img src={Leaf} alt="Leaf" className="img-fluid" width="40" />
                            <span className="ml-3">JAKARTA</span>
                        </div>
                    </div>
                </div>
                <div className="position-relative">
                    <div className="banner-line"></div>
                    <div className="banner-leaf">
                        <img src={BannerLeaf} alt="BannerLeaf" className="img-fluid" width="160" />
                    </div>
                    <div className="container">
                        <div className="banner-text">
                        <div className="mb-1">
                                <div>Empowering  
                                <span  className="font-medium ml-1">Visionary Founders.</span>
                                </div>                          
                            </div>
                            <div>
                                <div>Shaping the <span  className="font-medium">Future of Southeast Asia.</span></div>
                            </div>
                        </div>
                    </div>
                    <div className="banner-image">
                        <img src={Banner4} alt="Bannerimg" width="93%" className="img-fluid" />
                        <div className="container banner-image-text">
                            <img src={Leaf} alt="Leaf" className="img-fluid" width="40" />
                            <span className="ml-3">KUALA LUMPUR</span>
                        </div>
                    </div>
                </div>
                <div className="position-relative">
                    <div className="banner-line"></div>
                    <div className="banner-leaf">
                        <img src={BannerLeaf} alt="BannerLeaf" className="img-fluid" width="160" />
                    </div>
                    <div className="container">
                        <div className="banner-text">
                        <div className="mb-1">
                                <div>Empowering  
                                <span  className="font-medium ml-1">Visionary Founders.</span>
                                </div>                          
                            </div>
                            <div>
                                <div>Shaping the <span  className="font-medium">Future of Southeast Asia.</span></div>
                            </div>
                        </div>
                    </div>
                    <div className="banner-image">
                        <img src={Banner5} alt="Bannerimg" width="93%" className="img-fluid" />
                        <div className="container banner-image-text">
                            <img src={Leaf} alt="Leaf" className="img-fluid" width="40" />
                            <span className="ml-3">MANILA</span>
                        </div>
                    </div>
                </div>
                <div className="position-relative">
                    <div className="banner-line"></div>
                    <div className="banner-leaf">
                        <img src={BannerLeaf} alt="BannerLeaf" className="img-fluid" width="160" />
                    </div>
                    <div className="container">
                        <div className="banner-text">
                        <div className="mb-1">
                                <div>Empowering   
                                <span  className="font-medium ml-1">Visionary Founders.</span>
                                </div>                          
                            </div>
                            <div>
                                <div>Shaping the <span  className="font-medium">Future of Southeast Asia.</span></div>
                            </div>
                        </div>
                    </div>
                    <div className="banner-image">
                        <img src={Banner6} alt="Bannerimg" width="93%" className="img-fluid" />
                        <div className="container banner-image-text">
                            <img src={Leaf} alt="Leaf" className="img-fluid" width="40" />
                            <span className="ml-3">MUMBAI</span>
                        </div>
                    </div>
                </div>
            </Slider>
        </div>
    );
}

export default Banner;
