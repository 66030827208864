import { useState, useRef, useEffect } from "react";
import Mapimg from "../image/map.png";

function Map() {


    return (
        <div className="Map-wrapper">
            <div className="map-line">

            </div>
            <div className="map-heading">              
                Our focus is <span className="theme-text">driving scaleable </span>
                <span className="theme-text"> growth and success</span> across
                early stage  <span className="theme-text">Southeast Asia </span>
                businesses
            </div>
            <div>
                <img src={Mapimg} alt="Map" className="img-fluid" width="100%" />
            </div>
        </div>
    );
}

export default Map;
