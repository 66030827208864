import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import LandingPage from "./braxton/landing_page";
import Investment from "./braxton/investment";
import Team from "./braxton/team";
import Focus from "./braxton/focus";
import Portfolio from "./braxton/portfolio";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Routes>
    <Route path="/" element={<LandingPage/>}/>
    <Route path="/investment" element={<Investment/>}/>
    <Route path="/team" element={<Team/>}/>
    <Route path="/focus" element={<Focus/>}/>
    <Route path="/Portfolio" element={<Portfolio/>}/>
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
